























































import {Component, Prop, Vue} from 'vue-property-decorator';
import { OutputDeviceDTO } from '@/api/api';
import { EventBus } from '@/main';
import {lowerCaseWord} from "@/utils";
import DeviceStatusIcon from "@/components/DeviceStatusIcon.vue";
@Component({
  components: {DeviceStatusIcon}
})
export default class DeviceList extends Vue {
  @Prop(Number) readonly organizationId;
  @Prop(Number) readonly locationId;
  @Prop(String) readonly title;

  public headers = [
    {
      text: 'Status',
      sortable: true,
      value: 'statusIcon',
      align: 'center',
    },
    {
      text: 'ID',
      sortable: true,
      value: 'id',
      align: 'left',
    },
    {
      text: 'Name',
      sortable: false,
      value: 'name',
      align: 'left'
    },
    {
      text: 'Status Message',
      sortable: true,
      value: 'status',
      align: 'left',
    },
    {
      text: 'External ID',
      sortable: true,
      value: 'external_id',
      align: 'left',
    },
    {
      text: 'Actions',
      sortable: false,
      value: 'actions',
      align: 'center',
    },
  ];

  public devices: OutputDeviceDTO[]|null = null;

  public async created() {
    EventBus.$on('sse-device', () => {
      this.updateDevices();
    });
  }

  public async mounted() {
    this.updateDevices();
  }

  public async updateDevices() {
    if (this.$route.params.deviceId) {
      this.devices = await this.$store.dispatch('devices/getChildDevices', { deviceId: this.$route.params.deviceId })
    } else {
      this.devices = await this.$store.dispatch('locations/getDevices', { locationId: this.$route.params.locationId })
    }
  }

  lowerCaseWord = lowerCaseWord;

  get loading() {
    return this.devices === null;
  }
}
