var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"ma-3 pa-3"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v(_vm._s(_vm.title ? _vm.title : 'Devices'))]),_c('v-spacer')],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.devices || [],"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.statusIcon",fn:function(ref){
var item = ref.item;
return [_c('v-layout',{attrs:{"justify-center":""}},[_c('device-status-icon',{attrs:{"device-data":item}})],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.last_state ? _vm.lowerCaseWord(item.last_state.external_status) : '')+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-layout',{attrs:{"justify-center":""}},[_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","to":{
              name: 'main-maintenance-device-view',
              params: {
                organizationId: _vm.organizationId,
                locationId: _vm.locationId,
                deviceId: item.id
              }
            }}},[_c('v-icon',[_vm._v("pageview")])],1),_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","to":{
            name: 'main-maintenance-device-edit',
            params: {
              organizationId: _vm.organizationId,
              locationId: _vm.locationId,
              deviceId: item.id
            },
          }}},[_c('v-icon',[_vm._v("edit")])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }